<style scoped>
.handler {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<template>
  <div :style="control.style">
    <iframe
      frameborder="0"
      scrolling="auto"
      width="100%"
      height="100%"
      :src="control.config.url"
    ></iframe>
    <div class="handler" v-show="!isView"></div>
  </div>
</template>

<script>
export default {
  props: { control: Object, isView: Boolean },
};
</script>